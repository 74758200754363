//@ts-ignore
import React, {FC, useMemo} from "react";
import { Link } from 'gatsby'
import {ContentfulRemark} from "../../utils/common-types";
import {MarkdownContainer, SmHeader} from "../typography";
import {getHoverClasses} from "../../utils/get-hover-classes";


interface HoverSolutionBlockProps {
    solutionName: string;
    solutionDescription: ContentfulRemark;
    solutionPage: {slug: string};
    solutionHoverColor: string;
    className?: string;
}

const HoverSolutionBlock: FC<HoverSolutionBlockProps> = ({solutionName, solutionDescription, solutionPage, solutionHoverColor, className}) => (
    <Link to={`/solutions/${solutionPage.slug}`} className="w-full md:w-1/3">
        <div className={`h-full  focus:bg-none text-center text-white py-8 md:py-20 md:px-16 ${getHoverClasses(solutionHoverColor)} ${className || ''}`}>
            <SmHeader noPadding className="md:pb-4">{solutionName}</SmHeader>
            {solutionDescription && solutionDescription.childMarkdownRemark &&
                <MarkdownContainer className="hidden md:block">
                    {solutionDescription.childMarkdownRemark.html}
                </MarkdownContainer>
            }
        </div>
    </Link>
)

interface HoverSolutionsGridProps {
    blockData: HoverSolutionBlockProps[];
    bordered?: boolean;
}


const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

const HoverSolutionsGrid:FC<HoverSolutionsGridProps> = ({blockData, bordered}) => {
    const chunked = chunk(blockData, 3);
    const buildRow = (blockRows: HoverSolutionBlockProps[], bottomBorder: boolean) => (
        <div className={`flex flex-col md:flex-row w-full ${bottomBorder && 'border-b-2 border-gray-200'}`}>
            {blockRows.map((block, index) => {
                switch (index) {
                    case 0:
                    case 1:
                        return <HoverSolutionBlock {...block} key={index} className="border-b-2 md:border-b-0 md:border-r-2 border-gray-200"/>
                    default:
                        return <HoverSolutionBlock {...block} key={index} />
                }
            })}
        </div>
    )
    return (
        <section className={`border-t-2 border-b-2 border-gray-200 ${bordered ? 'border-r-2 border-l-2' : ''}`}>
            {chunked.map((row, i) => buildRow(row, i !== chunked.length - 1))}
        </section>
    )
}



export default HoverSolutionsGrid;
