import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

import "./work.css"
import HoverSolutionsGrid from "../components/landing-page-sections/hover-solutions-grid";
import {HoverColorVariant} from "../utils/get-hover-classes";
import {LGHeader} from "../components/typography";

const colors = [HoverColorVariant.Red, HoverColorVariant.Yellow, HoverColorVariant.Green, HoverColorVariant.Blue]

const SolutionIndex = ({location, data}) => {
  // const siteTitle = get(data, 'site.siteMetadata.title') siteTitle isn't working
  const allSolutions = get(data, 'allContentfulSolutionPage.edges')
  const solutions = allSolutions.map(({node}, index) => ({
    solutionName: node.title,
    solutionDescription: node.description,
    solutionPage: {slug: node.slug},
    solutionHoverColor: colors[index % colors.length]
  }))
  return (
    <Layout location={location}>
      <div>
        <Helmet>
          <title>Equity Solutions</title>
        </Helmet>
        <LGHeader className="p-8 md:p-16 text-white">Equity Solutions</LGHeader>
        <HoverSolutionsGrid blockData={solutions}/>
        <div className="p-8 md:p-16"></div>
      </div>
    </Layout>
  )
}

export default SolutionIndex

export const pageQuery = graphql`
  query SolutionIndexQuery {
    allContentfulSolutionPage {
      edges {
        node {
          sys {
            contentType { sys { id } }
          }
          updatedAt
          title
          slug
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
